@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap"); /* Import pixel-like font */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Press Start 2P", cursive; /* Apply pixel-like font everywhere */
  background-color: #f0f0f0;
  color: #333;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: #bbc0ee;
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
}

.pixel-font {
  font-family: "Press Start 2P", cursive; /* Pixel-like font */
  font-size: 2rem;
}

.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border: none;
  background-color: #bbc0ee;

  background-size: cover;
  padding: 20px;
}

.App-main.with-background {
  background-image: url("../public/mountain_bg_gizmoat.png");
}

.main-character-image {
  max-width: 80px;
  width: 20vw;
  margin: 20px 0;
}

.game-description {
  font-size: 1.2rem;
  color: white;
  padding: 0 20px;
  text-align: center;
}

.App-footer {
  background-color: #bbc0ee;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 10px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.footer-link:hover {
  text-decoration: underline;
}
